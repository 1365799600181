import { API } from '../shared/constants';
import {
    SchedulesTab,
    EntitySchedulesTab,
    SchedulesJobStatus,
    UploadScheduleDetail,
    ScheduleFilterParameters,
    SchedulesRoutesTab,
    EntitySchedulesRoutesTab,
    ScheduleRow,
    PCNList,
    PCNFiltersParameters,
    PCNRoutes,
    PCNAssignmentUser,
    PCNMarkerCoords,
    ScheduleRoutesFiltersParameters
} from '../shared/domain';
import { SchedulesBaseAdapter } from './schedules-base-adapter';
import { HttpConnectorAdapter } from './http-connector-adapter';
import { GridRowsProp } from '@mui/x-data-grid';
import { JobCreationResult, JobErrorResult } from '../models/jobs';
import { ValidAgGridRowModel } from '../shared/components/ag-grid-list';

interface BaseParameters extends Omit<ScheduleFilterParameters & PCNFiltersParameters, ''> {
    limit?: number;
    offset?: number;
}

let singletonInstance: SchedulesAdapter;
export class SchedulesAdapter implements SchedulesBaseAdapter {
    static getAdapter(): SchedulesBaseAdapter {
        if (!singletonInstance) {
            singletonInstance = new SchedulesAdapter();
        }

        return singletonInstance;
    }

    async getSchedules(limit: number, offset: number, filters = {} as ScheduleFilterParameters): Promise<SchedulesTab> {
        const params: BaseParameters = {};

        if (limit > 0) {
            params.limit = limit;
        }

        if (offset > 0) {
            params.offset = offset;
        }

        for (const filter in filters) {
            params[filter as keyof ScheduleFilterParameters] = filters[filter as keyof ScheduleFilterParameters];
        }

        const stringifiedParams = HttpConnectorAdapter.Utilities.stringifyGetParams(params);
        
        const response = await HttpConnectorAdapter
            .get(
                `${API.URL.SCHEDULES.SCHEDULES_BY_USER_ID}?${stringifiedParams}`
            );

        const result = SchedulesTab.create(response.data as EntitySchedulesTab);
        if (!result){
            return Promise.reject();
        }

        return result;
    }

    async submitSchedules(
        accountId: string,
        file: File,
        fileUploadDetails?: UploadScheduleDetail
    ): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('account_id', accountId);

        if (fileUploadDetails) {
            const {
                moduleGroupId,
                activeDate,
                expireDate
            } = fileUploadDetails;

            if (moduleGroupId) {
                formData.append('module_group_id', moduleGroupId);
            }

            if (activeDate) {
                formData.append('start_date', `${activeDate}`);
            }

            if (expireDate) {
                formData.append('expiration_date', `${expireDate}`);
            }
        }

        const headers = { 'Content-Type': 'multipart/form-data' };
        const response = await HttpConnectorAdapter
            .post(
                `${API.URL.SCHEDULES.SCHEDULES_BY_USER_ID}ingest/cbre`, formData, headers
            );

        const {
            status: responseStatus,
            data
        } : { 
            data?: {
                job_id: string;
            },
            status: boolean;
        } = response.data;

        if ((!responseStatus) || !data) {
            return Promise.reject();
        }

        const { job_id: jobId } = data;
        return Promise.resolve(jobId);
    }

    async getSubmitSchedulesStatus(jobId: string): Promise<SchedulesJobStatus> {
        const response = await HttpConnectorAdapter
            .get(
                API.URL.SCHEDULES.JOB_STATUS_BY_JOB_ID
                    .replace(API.URL.Tokens.SCHEDULES.JOB_ID, jobId)
            );

        const { 
            status: responseStatus,
            data
        } : {
            status: boolean,
            data?: SchedulesJobStatus
        } = response.data;

        if ((!responseStatus) || !data) {
            return Promise.reject();
        }
        return Promise.resolve(data);
    }

    async getSubmitSchedulesError(jobId: string): Promise<JobErrorResult> {
        const response = await HttpConnectorAdapter
            .get(
                API.URL.SCHEDULES.JOB_ERROR_BY_JOB_ID
                    .replace(API.URL.Tokens.SCHEDULES.JOB_ID, jobId)
            );

        const { 
            status: responseStatus,
            data
        } : {
            status: boolean,
            data?: JobErrorResult
        } = response.data;

        if ((!responseStatus) || !data) {
            return Promise.reject();
        }
        return Promise.resolve(data);
    }

    async submitSchedulesRoute(rows: GridRowsProp | ValidAgGridRowModel, pcnId?: string): Promise<JobCreationResult> {
        const {
            SCHEDULES: { SCHEDULE_ROUTES }
        } = API.URL;

        const body: {
            rows: GridRowsProp | ValidAgGridRowModel,
            pcn_id?: string
        } = { rows };

        if (pcnId) {
            body.pcn_id = pcnId;
        }

        const response = await HttpConnectorAdapter
            .post(
                SCHEDULE_ROUTES,
                { body }
            );

        const { 
            status: responseStatus,
            message,
            data
        } : {
            status: boolean,
            message: string,
            data: { job_id: string }
        } = response.data;

        if ((!responseStatus) || !data) {
            return Promise.reject();
        }

        const result: JobCreationResult = {
            status: responseStatus,
            message,
            jobId: data.job_id
        };

        return Promise.resolve(result);
    }

    async createSchedulePcn(pcnValue: string, folderId: string, shiftLabel: string): Promise<string> {
        const response = await HttpConnectorAdapter
            .post(`${API.URL.SCHEDULES.PCN}`,
                {
                    value: pcnValue,
                    folder_id: folderId,
                    shift_label: shiftLabel
                }
            );

        const { 
            status: responseStatus,
            message,
            data: { value }
        } : {
            status: boolean,
            message: string,
            data: { value: string }
        } = response.data;

        if ((!responseStatus)) {
            return Promise.reject();
        }

        return Promise.resolve(`${message} - PCN: ${value}`);
    }

    async editSchedulePcn(pcnId: string, folderId: string, pcnValue?: string, shiftLabel?: string): Promise<JobCreationResult | string> {
        const body: { [key: string]: string | number } = {
            id: Number(pcnId),
            folder_id: folderId
        };

        if (shiftLabel) {
            body.shift_label = shiftLabel;
        }

        if (pcnValue) {
            body.value = pcnValue;
        }

        const response = await HttpConnectorAdapter
            .patch(`${API.URL.SCHEDULES.PCN}`, body);

        const { 
            status: responseStatus,
            message,
            data: { value, shift_label, job_id }
        } : {
            status: boolean,
            message: string,
            data: { value?: string, shift_label?: string, job_id?: string }
        } = response.data;

        if ((!responseStatus)) {
            return Promise.reject();
        }

        const result = !pcnValue ? `${message} - PCN: ${value}, Shift Label: ${shift_label}` : {
            status: responseStatus,
            message,
            jobId: job_id
        } as JobCreationResult | string;

        return Promise.resolve(result);
    }

    async deleteSchedulePcn(pcnId: string): Promise<JobCreationResult> {
        const response = await HttpConnectorAdapter
            .delete(`${API.URL.SCHEDULES.PCN}/${pcnId}/`);

        const { 
            status: responseStatus,
            message,
            data: { job_id }
        } : {
            status: boolean,
            message: string,
            data: { job_id: string }
        } = response.data;

        if ((!responseStatus)) {
            return Promise.reject();
        }

        const result: JobCreationResult = {
            status: responseStatus,
            message,
            jobId: job_id
        };

        return Promise.resolve(result);
    }

    async getUsersRoutes(): Promise<SchedulesRoutesTab> {
        const response = await HttpConnectorAdapter.get(API.URL.SCHEDULES.ROUTES);

        const result = SchedulesRoutesTab.create(response.data as EntitySchedulesRoutesTab);
        if (!result){
            return Promise.reject();
        }

        return result;
    }

    async getSchedulesRoutes(folderId?: string, pcnId?: string): Promise<ScheduleRow[]> {
        const params: ScheduleRoutesFiltersParameters = {};

        if (folderId) {
            params.folder_id = folderId;
        }

        if (pcnId) {
            params.pcn_id = pcnId;
        }

        const response = await HttpConnectorAdapter.get(`${API.URL.SCHEDULES.SCHEDULE_ROUTES}?grid_format=true`, params);
        
        const { 
            status: responseStatus,
            data
        } : {
            status: boolean,
            data: ScheduleRow[]
        } = response.data;

        if ((!responseStatus) || !data) {
            return Promise.reject();
        }

        return Promise.resolve(data);
    }

    async addUserToPcnUserList(pcnId: string, userId: string, startTime: number, endTime?: number): Promise<string> {
        const response = await HttpConnectorAdapter
            .post(`${API.URL.SCHEDULES.ROUTES_ASSIGNMENT}`,
                {
                    secondary_id: pcnId,
                    user_id: userId,
                    start_time: startTime,
                    end_time: endTime ? endTime : 99999999999
                }
            );

        const { 
            status: responseStatus,
            message,
            data: { secondary_id }
        } : {
            status: boolean,
            message: string,
            data: { secondary_id: string }
        } = response.data;

        if ((!responseStatus)) {
            return Promise.reject();
        }

        return Promise.resolve(`${message} PCN ${secondary_id}`);
    }

    async createScheduleUserList(folderId: string, folderName: string, users: string[]): Promise<string> {
        const response = await HttpConnectorAdapter
            .post(`${API.URL.SCHEDULES.SCHEDULE_USER_LISTS}`,
                {
                    folder_doc_id: folderId,
                    name: folderName,
                    users: users
                }
            );

        const { 
            status: responseStatus,
            message,
            data: { name }
        } : {
            status: boolean,
            message: string,
            data: { name: string }
        } = response.data;

        if ((!responseStatus)) {
            return Promise.reject();
        }

        return Promise.resolve(`${message} Folder ${name}`);
    }

    async editScheduleUserList(userListId: string, folderName: string, users: string[]): Promise<string> {
        const response = await HttpConnectorAdapter
            .patch(`${API.URL.SCHEDULES.SCHEDULE_USER_LISTS}${userListId}`,
                {
                    name: folderName,
                    users: users
                }
            );

        const { 
            status: responseStatus,
            message,
            data: { name }
        } : {
            status: boolean,
            message: string,
            data: { name: string }
        } = response.data;

        if ((!responseStatus)) {
            return Promise.reject();
        }

        return Promise.resolve(`${message} Folder ${name}`);
    }

    async getPcnUserList(pcnId: string, current_one?: boolean): Promise<PCNAssignmentUser[]> {
        const {
            SCHEDULES: { ROUTES_ASSIGNMENT }
        } = API.URL;

        const url = `${ROUTES_ASSIGNMENT}?secondary_id=${pcnId}`;
        const response = await HttpConnectorAdapter.get(`${url}${ current_one ? '&current_one=true' : ''}`);
        
        const { 
            status: responseStatus,
            data
        } : {
            status: boolean,
            data: PCNAssignmentUser[]
        } = response.data;

        if ((!responseStatus) || !data) {
            return Promise.reject();
        }

        return Promise.resolve(data);
    }

    async getPCNList(limit: number, offset: number, filters = {} as PCNFiltersParameters): Promise<PCNRoutes> {
        const params: BaseParameters = {};

        if (limit > 0) {
            params.limit = limit;
        }

        if (offset > 0) {
            params.offset = offset;
        }

        for (const filter in filters) {
            params[filter as keyof PCNFiltersParameters] = filters[filter as keyof PCNFiltersParameters];
        }

        const {
            SCHEDULES: { PCN }
        } = API.URL;
        const response = await HttpConnectorAdapter.get(`${PCN}?current_assignment=true`, params);
        
        const { 
            status: responseStatus,
            data,
            meta: { total_count }
        } : {
            status: boolean,
            data: PCNList[],
            meta: { total_count: number }
        } = response.data;

        if ((!responseStatus) || !data) {
            return Promise.reject();
        }

        return Promise.resolve({ rows: data, total: total_count });
    }

    async getMarkersCoordinates(floorplanId: string): Promise<PCNMarkerCoords[]> {
        const {
            MAPS: { MARKERS },
            Tokens: { 
                MAPS: { FLOORPLAN_ID }
            }
        } = API.URL;

        const response = await HttpConnectorAdapter.get(MARKERS.replace(FLOORPLAN_ID, floorplanId));

        const {
            status: responseStatus,
            data: { markers }
        } : {
            status: boolean,
            data: {
                markers: PCNMarkerCoords[]
            }
        } = response.data;

        if (!responseStatus || !markers) {
            return Promise.reject();
        }

        return Promise.resolve(markers);
    }

    async getFloorplanImage(floorplanId: string): Promise<string> {
        const {
            MAPS: { PDF_IMAGE },
            Tokens: { 
                MAPS: { FLOORPLAN_ID }
            }
        } = API.URL;

        const response = await HttpConnectorAdapter.get(PDF_IMAGE.replace(FLOORPLAN_ID, floorplanId));

        const {
            status: responseStatus,
            data: { image }
        } : {
            status: boolean,
            data: {
                image: string
            }
        } = response.data;

        if (!responseStatus || !image) {
            return Promise.reject();
        }

        return Promise.resolve(image);
    }
}
